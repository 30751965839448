<template>
  <a-config-provider :locale="local">
    <div class="order-wrap">
      <div class="order-title">押金支付查询</div>
      <div class="order-main">
        <div class="order-form">
          <a-form :form="searchForm" layout="inline">
            <a-form-item
              class="flight-city-form-item"
              label="订单航段"
              label-align="left"
            >
              <FlightCityGroupSelector
                ref="citySelector"
                :depCity="cities.depCity"
                :arrCity="cities.arrCity"
                @depChange="onDepChange"
                @arrChange="onArrChange"
              />
            </a-form-item>
            <a-form-item label="订单号">
              <a-input
                size="small"
                placeholder="订单号"
                v-decorator="[
                  'searchForm.orderNo',
                  {
                    rules: [{ validator: handleFieldValidate }],
                  },
                ]"
              ></a-input>
            </a-form-item>
            <a-form-item label="支付时限">
              <a-range-picker
                size="small"
                v-decorator="['searchForm.payRangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="航班日期">
              <a-range-picker
                size="small"
                v-decorator="['searchForm.flightRangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="提交日期">
              <a-range-picker
                size="small"
                v-decorator="['searchForm.applyRangeTime']"
                show-time
                format="YYYY-MM-DD"
              />
            </a-form-item>
            <a-form-item label="支付状态">
              <a-select
                size="small"
                style="width: 100px"
                v-decorator="[
                  'searchForm.payStatus',
                  {
                    initialValue: '',
                  },
                ]"
              >
                <a-select-option v-for="item in payStatusOptions" :key="item.value" :value="item.value" >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-button size="small" type="primary" @click="onSeach">
                查询
              </a-button>
              <a-button size="small" type="primary" ghost @click="onReset">
                重置
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="order-table">
          <a-table
            :scroll="{x: 'max-content'}"
            :columns="columns"
            :data-source="tableData"
            :pagination="pageOptions"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :loading="loading"
            bordered
            @change="onTableChange"
          >
            <template slot="index" slot-scope="index">
              {{ index  }}
            </template>
            <template slot="orderNo" slot-scope="orderNo">
              <router-link
                :to="{
                  path: '/orderManager/orderDetail',
                  query: { id: orderNo },
                }"
                >{{ orderNo }}</router-link
              >
            </template>
            <template slot="segments" slot-scope="segments">
              <div v-for="(item, idx) in segments" :key="idx">
                {{ item.orgCity }}-{{ item.dstCity }}
              </div>
            </template>
            <template slot="payStatus" slot-scope="payStatus">
              <div>{{ payStatus | payStatus }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import tableBaseMixin from '@/common/mixin/table-base-mixin'
import orderSearchMixin from '@/common/mixin/order-search-mixin'
// import { validate } from '@/utils/validate'

import { format } from '@/filters/time'
import { status as payStatusOptions } from '@/dicts/pay'
import { status as payStatus } from '@/filters/pay'
import { airCity } from '@/filters/flight'

import FlightCityGroupSelector from '@/components/FightCityGroupSelector/FlightCityGroupSelector'
import { fetchDepositList } from '@/api/order'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    scopedSlots: { customRender: 'index' },
    align: 'center'
  },
  {
    title: '订单号',
    dataIndex: 'orderNo',
    scopedSlots: { customRender: 'orderNo' }
  },
  {
    title: '订单航段',
    dataIndex: 'segments',
    scopedSlots: { customRender: 'segments' }
  },
  {
    title: '提交人',
    dataIndex: 'submitUser'
  },
  {
    title: '押金期限',
    dataIndex: 'limit'
  },
  {
    title: '数量',
    dataIndex: 'number'
  },
  {
    title: '单价',
    dataIndex: 'fee'
  },
  {
    title: '支付比例',
    dataIndex: 'payRate'
  },
  {
    title: '押金金额',
    dataIndex: 'totalFee'
  },
  {
    title: '押金支付状态',
    dataIndex: 'payStatus',
    scopedSlots: { customRender: 'payStatus' }
  },
  {
    title: '提交时间',
    dataIndex: 'createTime'
  }
]

function dataParse (content, params) {
  const { page, size } = params

  return content.map((col, index) => {
    const segments = col.segmentInfos.map((seg) => {
      const { depCode, arrCode } = seg
      return { orgCity: airCity(depCode), dstCity: airCity(arrCode) }
    })

    return {
      key: col.orderNo,
      index: page * size + (index + 1),
      ...col,
      ...{
        segments: segments
      }
    }
  })
}

export default {
  name: 'depositPaySearch',
  components: {
    FlightCityGroupSelector
  },
  mixins: [tableBaseMixin, orderSearchMixin],
  data () {
    return {
      searchForm: this.$form.createForm(this, { name: 'search_form' }),
      columns,
      payStatusOptions,
      cities: {
        depCity: '',
        arrCity: ''
      },
      params: {
        depCode: '',
        arrCode: '',
        orderNo: '',
        limitStart: '',
        limitEnd: '',
        startFlightDate: '',
        endFlightDate: '',
        submitTimeStart: '',
        submitTimeEnd: '',
        payStatus: '',
        page: 0,
        size: 10
      }
    }
  },
  mounted () {
    this.fetchOrder()
  },
  methods: {
    async fetchOrder () {
      this.loading = true
      try {
        const result = await fetchDepositList(this.params)
        this.loading = false

        const { content, size, totalElements, number } = result
        this.tableData = dataParse(content, this.params)

        this.pageOptions.total = totalElements
        this.pageOptions.current = number + 1
        this.pageOptions.pageSize = size
      } catch (e) {
        this.loading = false
        this.$message.error(e.message)
      }
    },
    onSeach () {
      const fields = this.searchForm.getFieldsValue().searchForm
      const { cities } = this

      const params = {
        depCode: cities.depCity,
        arrCode: cities.arrCity,
        orderNo: fields.orderNo,
        payStatus: fields.payStatus
      }

      if (fields.payRangeTime) {
        params.limitStart = fields.payRangeTime[0] ? fields.payRangeTime[0].format('YYYY-MM-DD 00:00:00') : ''
        params.limitEnd = fields.payRangeTime[1] ? fields.payRangeTime[1].format('YYYY-MM-DD 23:59:59') : ''
      }

      if (fields.flightRangeTime) {
        params.startFlightDate = fields.flightRangeTime[0] ? fields.flightRangeTime[0].format('YYYY-MM-DD 00:00:00') : ''
        params.endFlightDate = fields.flightRangeTime[1] ? fields.flightRangeTime[1].format('YYYY-MM-DD 23:59:59') : ''
      }

      if (fields.applyRangeTime) {
        params.submitTimeStart = fields.applyRangeTime[0] ? fields.applyRangeTime[0].format('YYYY-MM-DD 00:00:00') : ''
        params.submitTimeEnd = fields.applyRangeTime[1] ? fields.applyRangeTime[1].format('YYYY-MM-DD 23:59:59') : ''
      }

      this.params = {
        ...this.params,
        ...params
      }

      this.fetchOrder()
    },
    handleFieldValidate (rule, value, callback) {
      // validate(
      //   'search-form',
      //   this.bookForm.getFieldsValue(),
      //   rule.fullField
      // ).then(({ valid, error }) => {
      //   if (valid) {
      //     callback()
      //   } else {
      //     callback(error)
      //   }
      // })
    },
    onTableChange (pagination) {
      this.params.page = pagination.current - 1
      this.params.size = pagination.pageSize

      this.fetchOrder()
    },
    onReset () {
      this.$refs.citySelector.reset()
      this.searchForm.resetFields()
    },
    onDepChange (city) {
      this.cities.depCity = city
    },
    onArrChange (city) {
      this.cities.arrCity = city
    }
  },
  filters: {
    payStatus,
    formatTime: format
  }
}
</script>

<style lang="less" scoped>
.flight-city-form-item {
  width: 300px;
}
</style>
